<!--
 * @Description: config form
 * @Author: zhangguoliang
 * @Date: 2021-08-16 09:26:09
 * @LastEditors: liuxia
 * @LastEditTime: 2022-02-16 09:32:31
-->
<template>
  <component
    :is="formVisible === undefined ? 'div' : 'a-modal'"
    v-model="formVisible"
    v-bind="modelAttrs"
    :afterClose="resetForm"
    @ok="submitForm"
  >
    <a-form-model
      v-bind="formAttrs"
      :model="formState"
      :labelCol="col.labelCol"
      :wrapperCol="col.wrapperCol"
      ref="formRef"
      class="cols-flex"
    >
      <template v-for="item in columns">
        <a-form-model-item
          v-bind="item.itemConfig"
          :key="item.itemConfig.prop"
          v-if="!item.hidden"
          :style="{ width: item.itemConfig.width || '50%' }"
        >
          <slot v-if="item.slot" :name="item.itemConfig.prop"></slot>
          <component
            v-else
            :is="componentsMap[item.type]"
            :placeholder="item.placeholder ? item.placeholder : ''"
            v-model="formState[item.itemConfig.prop]"
            v-bind="item.innerConfig"
            v-on="
              item.innerConfig && item.innerConfig.events
                ? item.innerConfig.events
                : {}
            "
          >
            <template
              v-if="item.innerConfig && item.innerConfig.slot"
              v-slot:[item.innerConfig.slot]
            >
              <slot
                :name="item.itemConfig.prop + '_' + item.innerConfig.slot"
              ></slot>
            </template>
          </component>
        </a-form-model-item>
      </template>
    </a-form-model>
    <a-form-model-item
      label=" "
      :colon="false"
      v-if="formVisible === undefined"
      class="oper-btns"
    >
      <slot name="operators" :form="formRef">
        <a-button type="primary" @click.prevent="submitForm">{{
          modelAttrs.okText || '提交'
        }}</a-button>
        <a-button @click.prevent="resetForm" style="margin-left: 10px">{{
          modelAttrs.cancelText || '重置'
        }}</a-button>
      </slot>
    </a-form-model-item>
    <template #footer v-if="modelAttrs.slot">
      <slot name="operators" :form="formRef"></slot>
    </template>
  </component>
</template>

<script>
import Vue from 'vue';
const componentsMap = {
  input: 'a-input',
  radio: 'a-radio-group',
  select: 'a-select',
  switch: 'a-switch',
  checkbox: 'a-checkbox-group',
  date: 'a-date-picker',
  textarea: 'a-textarea',
  password: 'a-input-password',
  upload: 'a-upload',
  number: 'a-input-number',
};
const valueTypeMap = {
  switch: 'checked',
  upload: 'file-list',
};

export default Vue.extend({
  name: 'ConfigForm',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: undefined,
    },
    formAttrs: {
      type: Object,
      default: () => ({}),
    },
    modelAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      componentsMap,
      formRef: null,
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.$nextTick(() => {
          this.formRef = this.$refs.formRef;
        });
      }
    },
  },
  computed: {
    col() {
      const { labelWidth = 80 } = this.formAttrs;
      return {
        labelCol: {
          span: 1,
          style: { width: labelWidth + 'px' },
        },
        wrapperCol: {
          span: 1,
          style: { width: `calc(100% - ${labelWidth}px)` },
        },
      };
    },
    columns() {
      return this.config.map((cfg) => ({
        ...cfg,
        valueType: cfg.valueType || valueTypeMap[cfg.type] || 'value',
      }));
    },
    formState: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
    formVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  mounted() {
    this.formRef = this.$refs.formRef;
  },
  methods: {
    submitForm() {
      this.formRef.validate((valid) => {
        if (!valid) return;
        this.$emit('submitForm', this.formState);
      });
    },
    resetForm() {
      this.formRef.resetFields();
      this.$emit('resetForm');
    },
  },
});
</script>

<style lang="less">
.cols-flex {
  display: flex;
  flex-wrap: wrap;
  .oper-btns {
    width: 100%;
  }
}
</style>
