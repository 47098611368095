var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('config-form',{staticClass:"common-dialog",attrs:{"value":_vm.formState,"config":_vm.formConfig,"formAttrs":{},"modelAttrs":{
    title: ((['add'].includes(this.$attrs.action) ? '新建' : '修改') + "工程项目"),
    width: '898px',
    maskClosable: false,
    confirmLoading: _vm.loading,
    slot: true,
  },"visible":_vm.showconfigform},on:{"update:value":function($event){_vm.formState=$event},"update:visible":function($event){_vm.showconfigform=$event},"submitForm":_vm.submitForm,"resetForm":_vm.close},scopedSlots:_vm._u([{key:"enterprises",fn:function(){return [(_vm.$attrs.userInfo.userType !== 'Normal ')?_c('span',[_vm._v(_vm._s(_vm.$attrs.userInfo.agencyName))]):_vm._e()]},proxy:true},{key:"projectSpaceName",fn:function(){return [_c('a-input',{model:{value:(_vm.formState.projectSpaceName),callback:function ($$v) {_vm.$set(_vm.formState, "projectSpaceName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formState.projectSpaceName"}})]},proxy:true},{key:"ssProvince",fn:function(){return [_c('div',{staticClass:"customwraps"},[_c('a-select',{staticClass:"spandivision",attrs:{"disabled":_vm.isDisabled},on:{"change":_vm.handlessProvince},model:{value:(_vm.formState.ssProvince),callback:function ($$v) {_vm.$set(_vm.formState, "ssProvince", $$v)},expression:"formState.ssProvince"}},_vm._l((_vm.ssProvince),function(item){return _c('a-select-option',{key:item.code,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('a-select',{staticClass:"spandivision",attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.formState.ssCity),callback:function ($$v) {_vm.$set(_vm.formState, "ssCity", $$v)},expression:"formState.ssCity"}},_vm._l((_vm.cityarr),function(item){return _c('a-select-option',{key:item.code,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]},proxy:true},{key:"coverUrl",fn:function(){return [_c('upload-img',{ref:"uploadimg",attrs:{"fileList":_vm.filelist},on:{"update:fileList":function($event){_vm.filelist=$event},"update:file-list":function($event){_vm.filelist=$event}}})]},proxy:true},{key:"operators",fn:function(ref){
  var form = ref.form;
return [(_vm.$attrs.action === 'add')?_c('div',{staticClass:"btn-footer"},[_c('div',{staticClass:"config-btn"},[_c('div',[_c('a-button',{staticClass:"create-btn",class:_vm.$attrs.userInfo &&
              _vm.$attrs.userInfo.agencyType &&
              _vm.$attrs.platformSurplus.split('次')[0] === '0'
                ? 'disabled-btn'
                : '',attrs:{"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.submitForm(form)}}},[_vm._v(" 立即创建工程项目 ")])],1),_c('div',{staticClass:"platformSurplus"},[_vm._v(" "+_vm._s(_vm.text)+"创建剩余"),_c('span',{class:_vm.$attrs.platformSurplus !== '0' ? 'number' : 'default'},[_vm._v(_vm._s(_vm.$attrs.platformSurplus))]),_vm._v("次 ")])]),(_vm.$attrs.userInfo.userType === 'Enterprise')?_c('div',{staticClass:"desc"},[_vm._v(" 创建项目需要归属企业管理员授权同意后创建成功 ")]):_vm._e()]):_c('div',{staticClass:"btn-footer"},[_c('a-button',{on:{"click":_vm.close}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.submitForm(form)}}},[_vm._v("确定")])],1),(
        _vm.$attrs.userInfo &&
        _vm.portalState.roleType !== 0 &&
        _vm.$attrs.platformSurplus === '0'
      )?_c('div',{staticClass:"add-banner",on:{"click":_vm.goBuy}},[_c('img',{attrs:{"src":require("@/assets/img/add-banner.png"),"alt":""}})]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }