



































































































































import Vue from 'vue';
import ConfigForm from '@/components/ConfigForm/index.vue';
import uploadImg from './upload.vue';
import * as api from '@/api/project';
import * as commonapi from '@/api/common';
import { coccMtdsResource_1_projectSpace } from '@/api/projectDetail';
import { cossMtdsResource_ProjectSpace_WhetherSpace_constructId } from '@/api/project';
import { mapGetters } from 'vuex';
interface cityarrs {
  [key: string]: string;
}
interface resData {
  [key: string]: any;
}
interface city {
  code: string;
  name: string;
}
const validatorName = (
  rule: [],
  value: string | Array<string>,
  callback: (msg?: string | Error) => unknown
): void => {
  // const patt = new RegExp(/^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/);
  if (!value) {
    callback(new Error('请输入工程项目名称'));
    return;
  }
  if (value.length > 50) {
    callback(new Error('工程项目名称不允许超出50个字'));
    return;
  }
  // const result = patt.test(value.toString());
  // if (!result) {
  //   callback(new Error('不能输入特殊字符'));
  //   return;
  // }
  callback();
};
export default Vue.extend({
  components: {
    ConfigForm,
    uploadImg,
  },
  props: {
    showFormStatus: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showFormStatus() {
      this.showconfigform = this.showFormStatus;

      if (this.showFormStatus) {
        if ((this.$attrs.userInfo as any).userType === 'Enterprise') {
          this.formConfig[0].itemConfig.label = '归属企业';
        } else {
          this.formConfig[0].itemConfig.label = '归属个人';
        }
        this.getcity();
        if (['edit'].includes(this.$attrs.action)) {
          this.find();
        }
      }
    },
  },
  computed: {
    ...mapGetters(['portalState']),
    text() {
      if ((this.$attrs.userInfo as any).userType === 'Enterprise') {
        return '所属企业工程项目';
      } else if ((this.$attrs.userInfo as any).userType === 'Personal') {
        return '当前个人工程项目';
      } else {
        return '当前工程项目';
      }
    },
  },
  data() {
    return {
      isDisabled: false,
      loading: false,
      filelist: [{ url: '' }],
      showconfigform: false,
      ssProvince: [{ code: '130000', name: '河北省' }],
      cityarr: [],
      formConfig: [
        {
          type: 'input',
          slot: 'enterprises',
          itemConfig: {
            label: '归属企业',
            prop: 'enterprises',
            width: '100%',
          },
        },
        {
          type: 'input',
          slot: 'projectSpaceName',
          itemConfig: {
            label: '项目名称',
            prop: 'projectSpaceName',
            width: '100%',
            rules: {
              required: true,
              trigger: 'change',
              validator: validatorName,
            },
          },
        },
        {
          type: 'select',
          slot: 'ssProvince',
          itemConfig: {
            label: '项目归属',
            prop: 'ssProvince',
            width: '100%',
            rules: {
              required: true,
              message: '项目归属归属地必选',
              trigger: 'change',
            },
          },
        },
        // {
        //   slot: 'coverUrl',
        //   itemConfig: {
        //     label: '项目封面',
        //     prop: 'coverUrl',
        //     width: '100%',
        //   },
        // },
      ],
      formState: {
        projectSpaceName: '',
        ssProvince: '130000',
        ssCity: '130100',
        coverUrl: '',
      },
      defaultImg: [
        'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/imagebg1.png',
        'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/imagebg3.png',
        'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/imagebg5.png',
      ],
      platformSurplus: '', // 所属企业工程项目创建剩余权益
    };
  },
  methods: {
    /**
     * @description: 获取人工费调整地区
     * @param {*}
     * @return {*}
     */
    getcity() {
      commonapi
        .project_getQuotaRatesParentByParentCode('130000')
        .then((res: any) => {
          this.cityarr = res.result;
          if (!this.formState.ssCity) {
            this.formState.ssCity = (this.cityarr[0] as cityarrs).code;
          }
        });
    },
    async submitForm(form: HTMLFormElement) {
      if (
        this.$attrs.userInfo &&
        (this.$attrs.userInfo as any).agencyType &&
        this.$attrs.platformSurplus.split('次')[0] === '0' &&
        ['add'].includes(this.$attrs.action)
      ) {
        return;
      }
      if (['edit'].includes(this.$attrs.action)) {
        const isHasOther: any = await this.isCanEdit();
        if (!isHasOther) {
          return;
        }
      }
      form.validate(async (valid: boolean) => {
        if (!valid) return;
        if (this.loading) {
          return;
        }
        this.loading = true;
        let index = Math.round(Math.random() * this.defaultImg.length);
        let img = this.defaultImg[index];
        this.formState.coverUrl = img;
        // this.formState.coverUrl = this.filelist[0].url
        //   ? this.filelist[0].url
        //   : this.defaultImg;
        const sscityarr = this.cityarr.filter((i: city) => {
          return i.code === this.formState.ssCity;
        });
        if (!sscityarr.length) {
          return this.$message.error('请检查市区配置');
        }
        const postdata = {
          ...this.formState,
          ssProvinceName: '河北省',
          ssCityName: (sscityarr[0] as city).name,
        };
        let resultdata;
        if (['add'].includes(this.$attrs.action)) {
          resultdata = await api
            .coccMtdsResource_1_projectSpace(postdata)
            .catch((err) => {
              if (err) {
                this.loading = false;
              }
            });
        } else {
          resultdata = await api
            .coccMtdsResource_1_constructProjectSpace_put({
              ...postdata,
              sequenceNbr: this.$attrs.id,
            })
            .catch((err) => {
              if (err) {
                this.loading = false;
              }
            });
        }
        if (resultdata && resultdata.status === 200) {
          this.$message.success('项目保存成功！');
          this.$emit('refresh');
          this.close();
          // 只有新增跳转
          // if (['add'].includes(this.$attrs.action)) {
          //   this.$router.push({
          //     path: '/project/detail',
          //     query: { sequenceNbr: resultdata.result.sequenceNbr },
          //   });
          // }
        }
        this.loading = false;
      });
    },
    isCanEdit() {
      return this.getProjectStatus();
    },
    async getProjectStatus() {
      const res: any =
        await cossMtdsResource_ProjectSpace_WhetherSpace_constructId(
          this.$attrs.constructId
        );
      if (res.status === 200 && !res.result.status) {
        this.$message.error(res.result.message);
        return false;
      }
      return true;
    },
    goBuy() {
      history.pushState(null, '', '/sub2/console/home');
    },
    close() {
      this.filelist = [{ url: '' }] as never;
      this.$emit('update:showFormStatus', false);
      this.isDisabled = false;
    },
    /**
     * @description: 查找详情
     * @param {*}
     * @return {*}
     */
    find() {
      coccMtdsResource_1_projectSpace(this.$attrs.id).then((res: resData) => {
        let { projectSpaceName, ssProvince, ssCity, coverUrl, extend1 } =
          res.result;
        this.formState = {
          projectSpaceName,
          ssProvince,
          ssCity,
          coverUrl,
        };
        this.filelist = [{ url: coverUrl }] as never;

        this.isDisabled =
          ['edit'].includes(this.$attrs.action) && [1].includes(+extend1);
      });
    },
    handlessProvince() {
      this.formState.ssCity = (this.cityarr[0] as cityarrs).code;
    },
  },
});
